<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title><h4>Ajout d'une conférence préalable</h4></v-card-title>
          <v-card-text>
            <v-text-field
              v-model="meeting.objet"
              name="input-7"
              label="Saisir l’objet de la conférence préalable"
            ></v-text-field>
            <date-picker
              label="Date de la conference"
              v-model="meeting.start_time"
              value-type="format"
              class="mb-2"
              :default-value="new Date()"
              format="YYYY-MM-DD H:m:s"
              type="datetime"
              placeholder="Renseigner la date/l’heure de la conférence préalable"
            ></date-picker>
            <v-autocomplete
              v-if="this.$store.getters['Auth/getListDoc']"
              v-model="meeting.document"
              :items="this.$store.getters['Auth/getListDoc']"
              item-text="object"
              item-value="id"
              outlined
              label="Choisir le dossier concerné par la conférence préalable"
            ></v-autocomplete>
            <v-text-field
              v-model="meeting.location"
              name="input"
              label="Saisir l’adresse de la conférence préalable (salle de visioconférence / ville/pays)"
            ></v-text-field>
            <v-text-field
              v-model="meeting.duration"
              name="input"
              type="number"
              label="Définir la durée de la conférence préalable (minute)"
            ></v-text-field>
            <v-text-field
              v-model="meeting.password"
              name="input"
              type="password"
              label="Définir le mot de passe pour accéder à la conférence préalable"
            ></v-text-field>
            <v-btn color="primary" small @click="addMeeting" class="ma-2 white--text" block>
              <v-icon small class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre pour programmer la conférence préalable
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol">
        <v-col v-if="profile != 3">
          <v-card class="mx-auto">
            <v-card-title>
              <h4>Liste détaillée des conférences préalables</h4>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{ this.$store.getters["Auth/getMeetingList"].length }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/getMeetingList']"
              :item-height="50"
              height="400"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-devices</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.object }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.meetingDate | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn @click="seemeeting(item)" depressed small>
                      Consulter
                      <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
        <v-col v-if="profile == 3">
          <v-card class="mx-auto">
            <v-card-title>
              <h4>Liste détaillée des conférences préalables</h4>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{ this.$store.getters["Auth/getMeetingList"].length }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/getMeetingList']"
              :item-height="50"
              height="400"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-devices</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.object }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.meetingDate | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn @click="seemeeting(item)" depressed small>
                      Consulter
                      <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    meeting: {
      objet: "",
      location: "",
      start_time: null,
      duration: "",
      password: "",
      document: ""
    },
    dynamicCol: 6
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;

    if (this.profile == 2) {
      this.dynamicCol = 12;
    }
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllMeeting");
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    addMeeting() {
      const data = new FormData();
      data.append("object", this.meeting.objet);
      data.append("location", this.meeting.location);
      data.append("document", this.meeting.document);
      data.append("start_time", this.meeting.start_time);
      data.append("duration", this.meeting.duration);
      data.append("password", this.meeting.password);
      this.$store.dispatch("Auth/addMeeting", data);

      this.meeting.objet = "";
      this.meeting.location = "";
      this.meeting.start_time = null;
      this.meeting.duration = "";
      this.meeting.password = "";
      this.meeting.document = "";
    },
    seemeeting(item) {
      console.log(item);
      this.$router.push({ name: "showMeeting", params: { id: item.id } });
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
